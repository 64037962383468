<template>
  <v-footer color="#9C7656">
    <v-layout row wrap justify-center align-center  pa-10>
      <v-flex xs12 md3 class="text-center" pa-2>
        <v-layout row wrap justify-center align-center>
          <v-flex xs2 md2>
            <v-card-text>
              <v-img @click="fn_pathAddress"
                width="30"
                src="@/assets/icon/home.png"
              ></v-img>
            </v-card-text>
          </v-flex>
          <v-flex xs8 md9>
            <v-card-text
            @click="fn_pathAddress"
              class="text-lg-left text-left"
              style="color: white; padding-bottom: 0px; padding-right: 0px; font-size: 17px"
            >
              <b> ที่อยู่ </b><br />
              <span>Rest is more 12 ซอย 3  <br> ถนน ภาสว่าง สงขลา 90110</span>
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md3 class="text-center" pa-2>
        <v-layout row wrap align-center justify-center>
          <v-flex xs2 md2>
            <v-card-text>
              <v-img @click="fn_pathFacebook"
                width="30"
                src="@/assets/icon/facebook.png"
              ></v-img>
            </v-card-text>
          </v-flex>
          <v-flex xs8 md9>
            <v-card-text @click="fn_pathFacebook"
              class="text-left"
              style="color: white; padding-bottom: 0px; padding-right: 0px; font-size: 17px"
            >
              <b>Facebook</b> <br />
              <span>Rest is more</span>
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-flex>
       <v-flex xs12 md3 class="text-center" pa-2>
        <v-layout row wrap align-center justify-center>
          <v-flex xs2 md2>
            <v-card-text>
              <v-img @click="fn_pathInstragram"
                width="30"
                src="@/assets/icon/instagram.png"
              ></v-img>
            </v-card-text>
          </v-flex>
          <v-flex xs8 md9>
            <v-card-text @click="fn_pathInstragram"
              class="text-left"
              style="color: white; padding-bottom: 0px; padding-right: 0px; font-size: 17px"
            >
              <b>Instagram</b> <br />
              <span>restismore.hatyai</span>
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-flex>
        <v-flex xs12 md3 class="text-center" pa-2>
        <v-layout row wrap align-center justify-center>
          <v-flex xs2 md2>
            <v-card-text>
              <v-img
                width="30"
                src="@/assets/icon/phone-call.png"
              ></v-img>
            </v-card-text>
          </v-flex>
          <v-flex xs8 md9>
            <v-card-text
              class="text-left"
              style="color: white; padding-bottom: 0px; padding-right: 0px; font-size: 17px"
            >
              <b>เบอร์ติดต่อ</b> <br />
              <span>091 795 7301</span>
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  methods: {
    fn_pathAddress () {
      window.open('https://www.google.com/maps/place/Rest+is+more+homemade+gelato+%7C+%E0%B9%80%E0%B8%88%E0%B8%A5%E0%B8%B2%E0%B9%82%E0%B8%95%E0%B9%89+%E0%B9%84%E0%B8%AD%E0%B8%A8%E0%B8%81%E0%B8%A3%E0%B8%B5%E0%B8%A1%E0%B9%82%E0%B8%AE%E0%B8%A1%E0%B9%80%E0%B8%A1%E0%B8%94/@7.0217598,100.491482,17z/data=!3m1!4b1!4m5!3m4!1s0x304d2974b5b0b2dd:0xd7d13752f347e3da!8m2!3d7.0217598!4d100.491482?hl=en-TH', '_blank')
    },
    fn_pathFacebook () {
      window.open('https://www.facebook.com/Restismore.hatyai', '_blank')
    },
    fn_pathInstragram () {
      window.open('https://www.instagram.com/restismore.hatyai/', '_blank')
    }
  }
}
</script>

<style></style>
