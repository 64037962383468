<template>
  <div id="fonttoolbar" style="overflow: initial">
    <v-app-bar
      app
      color="#CBB5A1"
      class="elevation-0"
      style="
        border-bottom-color: #0000001f !important;
        border-style: solid;
        border-width: 0 0 thin 0;
      "
    >
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <v-toolbar-title class="pt-2">
        <img src="@/assets/icon/logo.png" width="40" />
      </v-toolbar-title>
      <div class="flex-grow-1"></div>
      <div class="hidden-md-and-up">
        <v-btn
          text
          color="#5E4633"
          @click.stop.prevent="drawerLP = !drawerLP"
          style="font-weight: bold"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>
      <div class="hidden-sm-and-down">
        <v-btn text color="#5E4633" to="/">Home</v-btn>
        <v-btn text color="#5E4633" to="/our_gelato">OUR GELATO </v-btn>
         <v-btn text color="#5E4633" to="/gelato">GELATO </v-btn>
        <v-btn text color="#5E4633" to="/dessert">DESSERT </v-btn>
        <v-btn text color="#5E4633" to="/menu">MENU</v-btn>
        <v-btn text color="#5E4633" to="/blog">BLOG</v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer app temporary clipped :disable-resize-watcher="true" v-model="drawerLP" style="opacity: 0.7;">
      <v-divider></v-divider>
      <v-list style="text-align: left;" dense>
        <v-list-item link to="/">
          <v-list-item-title>HOME</v-list-item-title>
        </v-list-item>
          <v-list-item link to="/our_gelato">
          <v-list-item-title>OUR GELATO </v-list-item-title>
        </v-list-item>
          <v-list-item link to="/gelato">
          <v-list-item-title>GELATO </v-list-item-title>
        </v-list-item>
        <v-list-item link to="/dessert">
          <v-list-item-title>DESSERT</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/menu">
          <v-list-item-title>MENU</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/blog">
          <v-list-item-title>BLOG</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      menu: null,
      drawerLP: null
    }
  },
  computed: {},
  methods: {}
}
</script>

<style>
#fonttoolbar {
  font-family: 'Sarabun', sans-serif;
}
</style>
